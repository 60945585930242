<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col xl="6" lg="8" md="8" sm="10" xs="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Super activité" />
        </div>

        <!-- la barre de progression -->
        <v-row justify="center">
          <v-col xl="6" lg="8" md="8" sm="10" xs="12">
            <!-- la progess bar à afficher lors du chargement des données -->
            <v-progress-linear
              indeterminate
              :active="loading"
            ></v-progress-linear>
          </v-col>
        </v-row>

        <!-- le détail du service -->
        <DetailViewComponent title="Détail d'une super activité" :fields="fields" />
      </v-col>
    </v-row>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DetailViewComponent from "@/components/ui/DetailViewComponent.vue";

import { SuperActivitiesService } from "@/service/sfr/super_activities_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";

import * as exceptions from "@/service/exception_to_message.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { addFieldWithOneValue, addFieldWithSeveralValues, } from "@/components/ui/detail_view_component_tool.js";

export default {
  name: "DetailSuperActivité",
  components: { Workflow, TitleAndReturnComponent, DetailViewComponent },
  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** le service de chargement du service. */
      service: null,
      serviceActivities: null,

      /**l'identifiant de l'entité à visualiser. */
      entityId: null,

      /**Afficher le bouton de retour */
      showBackButton: false,

      /**les champs à afficher en détail */
      fields: [],
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        this.fields = [];

        //Récupération de l'item par son id
        let entity = await this.service.getById(this.entityId);
        // Récupère la liste des activités
        let activities = await this.serviceActivities.getActivities();

        // Récupère l'objet activité pour l'activité principale
        if (entity.mainActivity) {
          let main = activities.find((act) => act.id == entity.mainActivity);
          entity.main = main;
        }

        // Construit la liste des activités associés
        entity.listActivities = [];
        entity.activities.forEach((a) => {
          if (a != entity.mainActivity) {
            let act = activities.find((x) => x.id == a);
            entity.listActivities.push(act);
          }
        });


        addFieldWithOneValue(this.fields, "Nom digital", entity.digitalName || "-", true);
        addFieldWithOneValue(this.fields, "Nom complet", entity.name || "-", true);
        addFieldWithOneValue(this.fields, "Activité principale", entity.main.digitalName || "-", true);
        
        if (entity.listActivities.length > 0) {
          addFieldWithSeveralValues(this.fields, "Activités associées", entity.listActivities.map((a) => a.digitalName), false);
        } else {
          addFieldWithOneValue(this.fields, "Activités associées", "-", false);
        }
        
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {},
  mounted() {
    // Instanciation de la classe service
    this.service = new SuperActivitiesService(
      this.$api.getSuperActivitiesApi()
    );
    this.serviceActivities = new ActivityService(this.$api);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    //on affiche le bouton retour
    this.showBackButton = true;

    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>